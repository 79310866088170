<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    version="1.1"
  >
    <title>Error icon</title>
    <g id="chd-error-icon" :fill="fillColor" fill-rule="nonzero">
      <path
        d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10
                C0,4.4771525 4.4771525,0 10,0 Z M10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 C14.418278,18
                18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 Z M10,12.8333333 C10.8284271,12.8333333 11.5,13.5049062 11.5,14.3333333
                C11.5,15.1617605 10.8284271,15.8333333 10,15.8333333 C9.17157288,15.8333333 8.5,15.1617605 8.5,14.3333333 C8.5,13.5049062
                9.17157288,12.8333333 10,12.8333333 Z M10,4.33333333 C10.5522847,4.33333333 11,4.77121468 11,5.33626266 L11,10.330404
                C11,10.8447421 10.6172798,11.2686516 10.1173326,11.3265859 L10,11.3333333 C9.44771525,11.3333333 9,10.895452 9,10.330404
                L9,5.33626266 C9,4.82192456 9.38272018,4.39801509 9.88266744,4.34008077 L10,4.33333333 Z"
        id="icon_error"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'earl-1000',
    },
    size: {
      type: String,
      default: '1.25rem',
    },
  },

  computed: {
    fillColor() {
      return this.$vuetify.theme.currentTheme[this.color] || this.color;
    },
  },
};
</script>
