<template>
  <section class="tw-w-full">
    <div
      class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-center tw-items-center sm:tw-items-start"
    >
      <div
        v-if="pageAnnouncements"
        class="tw-flex-none tw-w-85 tw-mb-4 sm:tw-flex-auto sm:tw-max-w-176 tw-mx-4"
      >
        <Announcement :announcement="pageAnnouncements" />
      </div>
      <div class="tw-flex-none tw-w-85 tw-mx-4 tw-mb-4">
        <LoginForm />
      </div>
    </div>
  </section>
</template>

<script>
import LoginForm from '../components/LoginForm';
import Announcement from '../components/Announcement';

export default {
  components: {
    LoginForm,
    Announcement,
  },

  props: {
    pageAnnouncements: {
      type: String,
      default: '',
    },
  },
};
</script>
