<template>
  <article
    :class="`tw-w-full tw-p-4 sm:tw-p-5 tw-text-sm tw-border tw-rounded tw-shadow-portal tw-bg-white`"
  >
    <!-- Mobile/Tablet  -->
    <div class="lg:tw-hidden tw-flex tw-flex-col">
      <span class="tw-line-clamp-2">
        <AnnouncementIcon class="tw-inline" />
        {{ announcement }}
      </span>
      <div
        v-if="truncated"
        class="tw-lowercase tw-underline tw-cursor-pointer"
        @click="showDialog = true"
      >
        read more
      </div>
    </div>
    <!-- Desktop  -->
    <div class="tw-hidden lg:tw-flex lg:tw-flex-col">
      <span class="tw-line-clamp-5">
        <AnnouncementIcon class="tw-inline" />
        {{ announcement }}
      </span>
      <div
        v-if="truncated"
        class="tw-lowercase tw-underline tw-cursor-pointer"
        @click="showDialog = true"
      >
        read more
      </div>
    </div>
    <info-modal
      title="Announcements"
      @dialog-action="closeDialog"
      :show-dialog.sync="showDialog"
      :text="announcement"
    >
      <AnnouncementIcon class="tw-inline tw-mr-2" />
    </info-modal>
  </article>
</template>

<script>
import { debounce } from 'lodash';
import InfoModal from '@/common/components/InfoModal';
import AnnouncementIcon from '@/common/components/icons/AnnouncementIcon';

export default {
  components: { InfoModal, AnnouncementIcon },

  props: {
    announcement: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    truncated: false,
    showDialog: false,
  }),

  created() {
    window.addEventListener('resize', this.debouncedIsTruncated);
  },

  destroyed() {
    window.removeEventListener('resize', this.debouncedIsTruncated);
  },

  mounted() {
    this.isTruncated();
  },

  methods: {
    isTruncated: /* istanbul ignore next */ function () {
      // Determine if mobile/tablet client or desktop client
      const el =
        this.$el.querySelector('.tw-line-clamp-2').clientWidth > 0
          ? this.$el.querySelector('.tw-line-clamp-2')
          : this.$el.querySelector('.tw-line-clamp-5');

      this.truncated = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    },

    debouncedIsTruncated: debounce(
      /* istanbul ignore next */ function () {
        this.isTruncated();
      },
      500
    ),

    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
