<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" class="mr-3">
    <path
      d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1.6a6.4 6.4 0 100 12.8A6.4 6.4 0 008 1.6zm2.634 3.834a.8.8 0 111.132 1.132l-4.4 4.4a.8.8 0 01-1.132 0l-2.4-2.4a.8.8 0 111.132-1.132L6.8 9.28z"
      :fill="fillColor"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'earl-1000',
    },
    size: {
      type: String,
      default: '1.25rem',
    },
  },

  computed: {
    fillColor() {
      return this.$vuetify.theme.currentTheme[this.color] || this.color;
    },
  },
};
</script>
