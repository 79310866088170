import Vue from 'vue';
import Vuex from 'vuex';

import PortalRegistration from '@/portal/PortalRegistration.vue';
import vuetify from '@/plugins/vuetify';
import storeConfig from '@/portal/vuex/registration-store';
import '@/styles/tailwind.scss';
import '@/styles/common.scss';

Vue.config.productionTip = false;

Vue.use(Vuex);

document.addEventListener('DOMContentLoaded', async () => {
  const el = document.getElementById('portal-registration-vue');
  const portalData = JSON.parse(el.getAttribute('portal-data'));
  const gRecaptchaSiteKey = el.getAttribute('grecaptcha-site-key');

  const store = new Vuex.Store(storeConfig);
  await store.dispatch('setPortalSettings', portalData);
  await store.dispatch('setGRecaptchaSiteKey', gRecaptchaSiteKey);

  new Vue({
    el,
    vuetify,
    store,
    render: (h) => h(PortalRegistration),
  });
});
