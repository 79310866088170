<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1.6 C4.4653776,1.6 1.6,4.4653776 1.6,8 C1.6,11.5346224 4.4653776,14.4 8,14.4 C11.5346224,14.4 14.4,11.5346224 14.4,8 C14.4,4.4653776 11.5346224,1.6 8,1.6 Z M8.799,10.799 L9.19054031,10.8 C9.60320392,10.8 9.94374415,11.1025635 9.99369316,11.499044 L10,11.6 C10,12.0418278 9.64502668,12.4 9.19054031,12.4 L6.80945969,12.4 C6.36240745,12.4 6,12.0449081 6,11.6 C6,11.1581722 6.35497332,10.8 6.80945969,10.8 L7.199,10.799 L7.199,8.399 L6.79487801,8.4 C6.35587901,8.4 6,8.04490814 6,7.6 C6,7.1581722 6.3660511,6.8 6.79487801,6.8 L8.00512199,6.8 C8.41035184,6.8 8.74475755,7.10256348 8.79380677,7.49904401 L8.8,7.6 L8.799,10.799 Z M8,3.6 C8.6627417,3.6 9.2,4.1372583 9.2,4.8 C9.2,5.4627417 8.6627417,6 8,6 C7.3372583,6 6.8,5.4627417 6.8,4.8 C6.8,4.1372583 7.3372583,3.6 8,3.6 Z"
      id="alert-info"
      :fill="fillColor"
      fill-rule="nonzero"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'earl-1000',
    },
    size: {
      type: String,
      default: '1.25rem',
    },
  },

  computed: {
    fillColor() {
      return this.$vuetify.theme.currentTheme[this.color] || this.color;
    },
  },
};
</script>
