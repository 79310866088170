<template>
  <div>
    <div
      :class="`portal-gradient-${theme} tw-absolute tw-top-16 tw-w-full tw-h-29 sm:tw-h-58 tw-z-0`"
    ></div>
    <div class="tw-w-full tw-font-roboto tw-leading-6">
      <div class="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-20 sm:tw-h-35">
        <h2
          class="tw-justify-center tw-text-lg sm:tw-text-3xl md:tw-text-4.5xl tw-text-white tw-font-extrabold tw-z-10"
        >
          {{ welcomeMessage }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: true,
    },
    welcomeMessage: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.portal-gradient-blue {
  background: linear-gradient(315deg, #2099bd 0%, #0f3a8e 100%);
}
.portal-gradient-red {
  background: linear-gradient(315deg, #e97472 0%, #bf211e 100%);
}
.portal-gradient-orange {
  background: linear-gradient(315deg, #ff9e1b 0%, #ff7f32 100%);
}
.portal-gradient-grey {
  background: linear-gradient(315deg, #989a9c 0%, #54585a 100%);
}
.portal-gradient-green {
  background: linear-gradient(315deg, #6da744 0%, #4c742f 100%);
}
.portal-gradient-purple {
  background: linear-gradient(315deg, #75559f 0%, #3a2a4f 100%);
}
.portal-gradient-indigo {
  background: linear-gradient(315deg, #1044aa 0%, #0a2966 100%);
}
.portal-gradient-cyan {
  background: linear-gradient(315deg, #49a3aa 0%, #3a8288 100%);
}
</style>
