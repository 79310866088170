<template>
  <nav
    class="tw-flex tw-items-center tw-justify-between tw-w-full tw-h-16 tw-px-4 tw-text-sm tw-bg-white"
  >
    <div class="tw-flex tw-items-center tw-h-full">
      <a
        class="tw-flex tw-flex-col tw-justify-center tw-h-full tw-no-underline tw-mr-2 hover:tw-opacity-50 tw-cursor-pointer tw-text-earl-1000"
        @click="navigateHome"
      >
        <img v-if="logoUrl" :src="logoUrl" class="tw-h-12 tw-mr-4 lg:tw-mr-16 tw-rounded-lg" />
        <span v-else class="tw-font-bold tw-w-20 lg:tw-w-36 tw-truncate">
          {{ organizationName }}
        </span>
      </a>
      <a
        v-if="currentUser && $vuetify.breakpoint.mdAndUp"
        :class="`tw-flex tw-flex-col tw-justify-center tw-h-full tw-px-2 hover:tw-opacity-50 tw-cursor-pointer tw-font-bold tw-text-earl-1000 ${activeClasses}`"
        @click="navigateHome"
      >
        <span>Home</span>
      </a>
    </div>

    <!-- Only user email and form submit button for authenticated portal -->
    <div v-if="currentUser" class="tw-flex">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        to="/ticket"
        class="pl-2 pr-3 font-weight-bold"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span class="tw-normal-case tw-text-base tw-tracking-normal">Submit a ticket</span>
      </v-btn>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="tw-whitespace-no-wrap tw-ml-5 lg:tw-ml-10" v-bind="attrs" v-on="on">
            <avatar :color="theme" :size="36" class="mr-2" :user="currentUser" />
            <span v-if="$vuetify.breakpoint.mdAndUp" class="email">{{ currentUser.email }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </div>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </nav>
</template>

<script>
/* istanbul ignore file */
import { logout } from '../services/registrations-service';
import Avatar from '@/common/components/Avatar';

export default {
  components: { Avatar },

  props: {
    logoUrl: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String,
      required: true,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },

  computed: {
    activeClasses() {
      return this.$route.path === '/' ? 'tw-border-earl-1000 tw-border-b-2  tw-pt-1' : '';
    },
  },

  methods: {
    async logout() {
      await logout();
      window.location.reload();
    },

    navigateHome(e) {
      e.preventDefault();
      if (this.currentUser && this.$route.path !== '/') {
        this.$router.push({ name: 'Landing' });
      }
    },
  },
};
</script>

<style scoped>
.email {
  max-width: 12rem;
  @apply tw-inline-block tw-truncate tw-align-middle tw-text-earl-1000;
}
</style>
