<template>
  <v-app v-resize="onResize" class="tw-bg-earl-100">
    <NavBar
      :logoUrl="portalSettings.logoUrl"
      :theme="portalSettings.theme"
      :organizationName="portalSettings.organizationName"
      :currentUser="currentUser"
    />
    <Title
      :theme="portalSettings.theme"
      :welcomeMessage="portalSettings.welcomeMessage"
      v-if="showTitle"
    />
    <v-main class="tw-text-earl-1000 tw-font-medium tw-text-sm">
      <v-container fluid class="pa-0">
        <slot></slot>
      </v-container>
    </v-main>
    <Footer v-if="showFooter" />
    <Snackbar />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Title from '../components/Title';
import Snackbar from '../../common/components/Snackbar';

export default {
  components: {
    NavBar,
    Footer,
    Title,
    Snackbar,
  },

  data() {
    return {
      windowHeight: null,
      windowWidth: null,
    };
  },

  computed: {
    ...mapState(['portalSettings', 'currentUser']),

    showTitle: function () {
      const exclude = ['Ticket'];
      return !(
        this.$route &&
        exclude.includes(this.$route.name) &&
        (this.windowHeight < 640 || this.windowWidth < 640)
      );
    },

    showFooter: function () {
      const exclude = ['Ticket'];
      return !(this.$route && exclude.includes(this.$route.name) && this.windowWidth < 1024);
    },
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;

      this.$emit('set-viewport-height');
    },
  },
};
</script>

<style>
.evidon-consent-button {
  visibility: hidden !important;
}
</style>
