<template>
  <v-snackbar
    v-model="snackbarProps.display"
    :color="colors[snackbarProps.type] || colors['info']"
    rounded="true"
    elevation="24"
    transition="slide-y-transition"
    class="mt-10"
    :timeout="snackbarProps.timeout"
    top
  >
    <slot>
      <v-card-text class="pa-0 d-flex align-center">
        <template v-if="icons[snackbarProps.type]">
          <component :is="icons[snackbarProps.type]" color="white" />
        </template>
        <ul class="chd-list" v-if="hasMultipeMessages">
          <li v-for="(item, index) in message" :key="index">{{ item }}</li>
        </ul>
        <div class="tw-pl-3" v-else>
          {{ displayMessage }}
        </div>
      </v-card-text>
    </slot>
    <template v-slot:action v-if="snackbarProps.type !== 'processing'">
      <v-icon class="pr-1" :ripple="false" flat @click="close" v-if="!snackbarProps.hideCloseButton"
        >mdi-close-circle-outline</v-icon
      >
    </template>
  </v-snackbar>
</template>

<script>
import { capitalize, isPlainObject } from 'lodash';
import { mapState, mapActions } from 'vuex';

import InfoIcon from './icons/InfoIcon';
import SuccessIcon from './icons/SuccessIcon';
import ErrorIcon from './icons/ErrorIcon';

export default {
  components: { SuccessIcon, ErrorIcon, InfoIcon },

  data() {
    return {
      icons: {
        info: InfoIcon,
        success: SuccessIcon,
        error: ErrorIcon,
        warn: ErrorIcon,
      },
      colors: {
        info: 'info-500',
        error: 'error',
        success: 'success-500',
        warn: 'info-500',
      },
    };
  },

  computed: {
    ...mapState(['snackbarProps']),

    icon() {
      return this.icons[this.snackbarProps.type];
    },

    message() {
      const { errors } = this.snackbarProps.message;
      return errors ? errors : this.snackbarProps.message;
    },

    hasMultipeMessages() {
      const message = this.message;

      if (isPlainObject(message)) {
        const keys = Object.keys(message);
        return keys.length > 1 || (Array.isArray(message[keys[0]]) && message[keys[0]].length > 1);
      }

      return Array.isArray(message) && message.length > 1;
    },

    objectMessages() {
      const message = this.message;
      let messages = [];
      Object.keys(message).forEach((key) => {
        messages = messages.concat(
          Array.isArray(message[key]) && message[key].map((m) => `${capitalize(key)} ${m}`)
        );
      });

      return messages;
    },

    displayMessage() {
      const message = this.message;
      if (Array.isArray(message)) return message[0];
      if (isPlainObject(message)) return this.objectMessages[0];

      // FIXME: handle other server side errors
      if (message.includes('<html')) return 'Oops! something went wrong, please try again';
      return message;
    },
  },

  methods: {
    ...mapActions(['showSnackbar']),

    close() {
      this.showSnackbar({ display: false });
    },
  },
};
</script>

<style>
.chd-list,
.chd-list li {
  list-style: disc outside none;
}
</style>
