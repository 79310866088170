<template>
  <div class="text-center">
    <v-dialog
      content-class="tw-text-earl-1000"
      v-model="showDialog"
      @click:outside="$emit('dialog-action')"
      @keydown.esc="$emit('dialog-action')"
      class="chd-modal"
      :fullscreen="$vuetify.breakpoint.smOnly"
      :max-width="maxWidth"
      scrollable
    >
      <v-card>
        <v-card-title class="pr-2 pl-4">
          <slot>
            <!-- Can be used to pass an icon to prepend to title or a custom title HTML-->
          </slot>
          <span class="tw-text-2xl tw-font-bold" v-if="title">{{ title }}</span>
          <v-spacer />
          <v-btn icon @click="$emit('dialog-action')" class="tw--mt-4">
            <v-icon color="earl-700">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-4">
          <div class="tw-text-earl-1000 tw-font-medium">
            {{ text }}
          </div>
          <slot name="content">
            <!-- Body -->
          </slot>
        </v-card-text>

        <section v-if="$vuetify.breakpoint.smOnly">
          <v-divider />
          <v-spacer />
        </section>

        <v-card-actions v-if="showActionButtons" class="px-4 sm:py-3">
          <v-spacer />
          <slot name="buttonActions">
            <!-- Custom Buttons -->
          </slot>
          <v-btn
            @click="$emit('dialog-action')"
            class="btn-close"
            :color="buttonColor"
            :tile="hasButtonActionSlot"
            :outlined="hasButtonActionSlot"
            depressed
            v-if="showCloseButton"
          >
            <span class="tw-normal-case tw-font-normal tw-text-base tw-tracking-normal">Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '600px',
    },
    showActionButtons: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasButtonActionSlot() {
      // Make the extra close button to be secondary
      return !!this.$slots.buttonActions == true;
    },
    buttonColor() {
      // Make the extra close button to be secondary
      return this.hasButtonActionSlot ? '' : 'primary';
    },
  },
};
</script>

<style scoped>
.btn-close {
  padding: 0 0.5rem !important;
  @apply tw-font-semibold tw-normal-case tw-px-1 tw-rounded-sm tw-mr-1 tw-border-earl-200 !important;
}
</style>
