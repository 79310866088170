import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/styles/vuetify.scss';

/* istanbul ignore next */
Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#2099bd', // Tailwind dusk-400
        error: '#e51e17',
        'info-500': '#4a4a4a',
        'success-500': '#2db960',
        'earl-700': '#434648',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
};

export default new Vuetify(opts);
