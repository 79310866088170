<template>
  <v-avatar :size="size" :color="backgroundColor" :style="borderStyle">
    <img
      v-show="loaded"
      :src="user.avatarUrlNoDefault"
      :alt="user.email"
      @load="onLoaded"
      @error="loaded = false"
    />
    <span v-show="!loaded" :class="`tw-text-${fontColor} tw-font-medium`" :style="initialsStyle">{{
      initials
    }}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: 'orange',
    },
    size: {
      type: Number,
      default: '36',
    },
  },

  data: () => ({
    loaded: false,
    backgroundColor: '',
    borderColor: 'transparent',
    fontColor: 'white',
  }),

  mounted() {
    this.setColors();
  },

  computed: {
    initials() {
      const { firstName, lastName, email } = this.user;
      let initials = '';

      if (firstName && lastName) {
        initials = firstName[0] + lastName[0];
      } else if (firstName) {
        initials = firstName[0];
      } else if (lastName) {
        initials = lastName[0];
      } else {
        // If end user doesn't have a first name or a last name, use first character of email
        initials = email[0];
      }

      // Remove non-alphanumeric and whitespace characters from initials. If left with an empty string, use first character of email
      initials = initials.replaceAll(/[^A-Za-z0-9]/g, '');
      if (initials.length === 0) initials = email[0];

      return initials.toUpperCase();
    },

    initialsStyle() {
      const fontSize = this.size * 0.5;
      return `font-size: ${fontSize}px;`;
    },

    theme() {
      switch (this.backgroundColor) {
        case 'blue':
          return '#2099BD';
        case 'red':
          return '#E97472';
        case 'orange':
          return '#FF9E1B';
        case 'grey':
          return '#989A9C';
        case 'green':
          return '#6DA744';
        case 'purple':
          return '#75559F';
        case 'indigo':
          return '#1044AA';
        case 'cyan':
          return '#49A3AA';
        default:
          // White
          return '#FFF';
      }
    },

    borderStyle() {
      if (this.borderColor === 'transparent') return '';
      return `border-width: 1px !important; border-color: ${this.borderColor} !important;`;
    },
  },

  methods: {
    onLoaded() {
      this.loaded = true;
    },

    setColors() {
      // Set avatar colors for deleted users and techs
      if (this.user.name === '[deleted user]' || this.user.name === '[deleted tech]') {
        this.backgroundColor = 'white';
        this.fontColor = 'earl-400';
        this.borderColor = '#989a9c'; // earl-400
      } else {
        this.backgroundColor = this.color;
      }
    },
  },
};
</script>
