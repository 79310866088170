<template>
  <portal-layout>
    <Login :pageAnnouncements="portalSettings.pageAnnouncements" />
  </portal-layout>
</template>

<script>
import { mapState } from 'vuex';
import Login from './views/Login';
import PortalLayout from './views/PortalLayout';

export default {
  components: {
    Login,
    'portal-layout': PortalLayout,
  },

  computed: {
    ...mapState(['portalSettings']),
  },
};
</script>
