<template>
  <div class="tw-w-full tw-p-4 sm:tw-p-6 tw-border tw-rounded tw-shadow-portal tw-bg-white">
    <div class="tw-font-bold tw-text-xl tw-leading-8">Log in to submit tickets</div>
    <v-form ref="form" class="tw-flex tw-flex-col tw-pt-4" @submit.prevent="onSubmit">
      <vue-recaptcha
        @verify="onVerify"
        ref="invisibleRecaptcha"
        size="invisible"
        :sitekey="gRecaptchaSiteKey"
      ></vue-recaptcha>

      <v-text-field
        v-model="email"
        :error-messages="emailErrors"
        label="Work Email"
        outlined
        dense
      ></v-text-field>

      <div class="tw-flex tw-flex-row tw-mt-2">
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading"
          class="font-weight-bold"
          color="primary"
          depressed
        >
          <span class="tw-normal-case tw-text-base tw-tracking-normal">Log in</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import { requestLogin } from '../services/registrations-service';

export default {
  components: { 'vue-recaptcha': VueRecaptcha },

  mixins: [validationMixin],

  data: () => ({
    email: '',
    gRecaptchaResponse: '',
    loading: false,
  }),

  validations: {
    email: { required, email },
  },

  computed: {
    ...mapState(['gRecaptchaSiteKey']),

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid email.');
      !this.$v.email.required && errors.push('Email is required.');
      return errors;
    },
  },

  methods: {
    ...mapActions(['showSnackbar']),

    async onVerify(gRecaptchaResponse) {
      this.loading = true;

      const payload = {
        email: this.email,
        'g-recaptcha-response': gRecaptchaResponse,
      };

      const response = await requestLogin(payload);

      this.loading = false;

      const snackbarProps = {
        display: true,
        type: 'success',
        message: response.message,
      };

      if (response.errors) {
        snackbarProps.type = 'error';
        snackbarProps.message = response.errors;
      }

      this.showSnackbar(snackbarProps);
      this.$refs.invisibleRecaptcha.reset();
      this.$refs.form.reset();
      this.$v.$reset();
    },

    /* istanbul ignore next */
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        window.ON_PREM ? this.onVerify('') : this.$refs.invisibleRecaptcha.execute();
      }
    },
  },
};
</script>
